<div class="left-sidenav" id="accordionExample">
  <div class="main-menu-inner">
    <div class="menu-body sidebar-nav">
      <ul class="nav" data-widget="treeview" role="menu" data-accordion="false">
        <li class="nav-item"
          *hasClaim="['DB_STATISTICS','DB_BEST_SELLING_PROS','DB_REMINDERS','DB_LATEST_INQUIRIES','DB_RECENT_SO_SHIPMENT','DB_RECENT_PO_DELIVERY']">
          <a [routerLink]="['/']" [ngClass]="{'active': currentUrl == 'dashboard'}" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact:true}" class="nav-link">
            <i class="nav-icon fas fa-tachometer-alt"></i>
            <p>
              {{'DASHBOARD' | translate}}
            </p>
          </a>
        </li>
        
        <li id="CustomerHeading" aria-expanded="true" aria-controls="collapseOne"
          *hasClaim="['CNT_VIEW_CONTACT','CNT_ADD_CONTACT']" data-toggle="collapse" data-target="#Customer"
          class="collapsed">
          <a class="nav-link" [ngClass]="{'active': currentUrl == 'customer'}">
            <i class="nav-icon fas fa-users-cog"></i>
            <span>{{'Contact' | translate}}</span>
            <span class="fas fa-angle-down rotate-arrow ml-auto"></span>
          </a>
        </li>
        <ul class="sub-menu collapse" id="Customer" [ngClass]="{'show': currentUrl == 'customer'}"
          aria-labelledby="CustomerHeading" data-parent="#accordionExample">
          <li *hasClaim="['CNT_VIEW_CONTACT','CNT_ADD_CONTACT']" class="nav-item">
            <a [routerLink]="['/customer']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>{{'LIST' | translate}}</p>
            </a>
          </li>
          <li *hasClaim="'CNT_ADD_CONTACT'" class="nav-item">
            <a [routerLink]="['/customer/add']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="fas fa-plus-square nav-icon"></i>
              <p>{{'ADD_NEW' | translate}}</p>
            </a>
          </li>
        </ul>

        <li *hasClaim="['INT_ADD_INTERACTION','INT_VIEW_INTERACTION','INT_DELETE_INTERACTION','INT_UPDATE_INTERACTION']" id="InteractionsHeading" aria-expanded="true" aria-controls="collapseOne"
        data-toggle="collapse" data-target="#Interactions"
        class="collapsed">
          <a class="nav-link" [ngClass]="{'active': currentUrl == 'interactions'}" >
            <i class="far fa-list-alt nav-icon"></i>
            <span>{{'Interactions' | translate}}</span>
            <span class="fas fa-angle-down rotate-arrow ml-auto"></span>
          </a>
        </li>
        <ul class="sub-menu collapse" id="Interactions" [ngClass]="{'show': currentUrl == 'interactions'}"
        aria-labelledby="CustomerHeading" data-parent="#accordionExample">
        <li *hasClaim="'INT_VIEW_INTERACTION'" class="nav-item">
          <a [routerLink]="['/interactions']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
            class="nav-link">
            <i class="far fa-list-alt nav-icon"></i>
            <p>All List</p>
          </a>
        </li>
        <li *hasClaim="'INT_VIEW_MY_INTERACTION'" class="nav-item">
          <a [routerLink]="['/interactions/My-interaction']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
            class="nav-link">
            <i class="far fa-list-alt nav-icon"></i>
            <p>My list </p>
          </a>
        </li>
        <li *hasClaim="'INT_VIEW_TEAM_INTERACTION'" class="nav-item">
          <a [routerLink]="['/interactions/Team-interaction']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
            class="nav-link">
            <i class="far fa-list-alt nav-icon"></i>
            <p>Team list</p>
          </a>
        </li>
        <li *hasClaim="'INT_VIEW_EMAILL0'" class="nav-item">
          <a [routerLink]="['/interactions/l0-email']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
            class="nav-link">
            <i class="fas fa-plus-square nav-icon"></i>
            <p>L0 Email</p>
          </a>
        </li>
        <li *hasClaim="'INT_VIEW_MANNUALASSIGN'" class="nav-item">
          <a [routerLink]="['/interactions/assign-interaction']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
            class="nav-link">
            <i class="fas fa-plus-square nav-icon"></i>
            <p>Manual Assignment</p>
          </a>
        </li> 
        <li class="nav-item" *hasClaim="['INT_BULK_INTERACTION_CLOSER','INT_BULK_INTERACTION_CLOSER_HISTORY']">
          <a [routerLink]="['/interaction/interaction-bulk-closer']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
            class="nav-link">
            <!-- <i class="far fa-list-alt nav-icon"></i> -->
            <i class="fa fa-upload nav-icon"></i>
            <p>Interaction Bulk Closer</p>
          </a>
        </li>
        <li class="nav-item" *hasClaim="['INT_BULK_INTERACTION_TRANSFER']">
          <a [routerLink]="['/interaction/interaction-bulk-transfer']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
            class="nav-link">
            <!-- <i class="far fa-list-alt nav-icon"></i> -->
            <i class="fa fa-upload nav-icon"></i>
            <p>Interaction Bulk Transfer</p>
          </a>
        </li>
        <!-- <li class="nav-item" *hasClaim="['INT_BULK_INTERACTION_CLOSER','INT_BULK_INTERACTION_CLOSER_HISTORY']">
          <a [routerLink]="['/interaction/internal-bulk-closer']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
            class="nav-link">
            <i class="fa fa-upload nav-icon"></i>
            <p>Internal Bulk Closer</p>
          </a>
        </li> -->
      </ul>

        <li id="UsersHeading" aria-expanded="true" aria-controls="collapseOne"
          *hasClaim="['USR_VIEW_USERS','USR_ADD_USER','USR_ASSIGN_USR_ROLES','USR_ONLINE_USERS']" data-toggle="collapse"
          data-target="#Users" class="collapsed">
          <a class="nav-link" [ngClass]="{'active': currentUrl == 'users'}">
            <i class="nav-icon fa fa-users"></i>
            <span>{{'USERS' | translate}}</span>
            <span class="fas fa-angle-down rotate-arrow ml-auto"></span>
          </a>
        </li>
        <ul class="sub-menu collapse" id="Users" aria-labelledby="UsersHeading"
          [ngClass]="{'show': currentUrl == 'users'}" data-parent="#accordionExample">
          <li *hasClaim="'USR_VIEW_USERS'" class="nav-item">
            <a [routerLink]="['/users']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>{{'LIST' | translate}}</p>
            </a>
          </li>
          <li *hasClaim="'USR_ADD_USER'" class="nav-item">
            <a [routerLink]="['/users/manage']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="fas fa-plus-square nav-icon"></i>
              <p>{{'ADD_NEW' | translate}}</p>
            </a>
          </li>
          <li *hasClaim="'USR_ASSIGN_USR_ROLES'" class="nav-item">
            <a [routerLink]="['/roles/users']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="fas fa-user-shield nav-icon"></i>
              <p>{{'USER_ROLES' | translate}}</p>
            </a>
          </li>
          <li *hasClaim="'USR_ADD_BULK_USER'" class="nav-item">
            <a [routerLink]="['users/users-bulk-upload']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="fas fa-user-shield nav-icon"></i>
              <p>User Bulk Upload</p>
            </a>
          </li>
          <li *hasClaim="'USR_ONLINE_USERS'" class="nav-item">
            <a [routerLink]="['/sessions']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="nav-icon fa fa-chalkboard-teacher"></i>
              <p>
                {{'CURRENT_ONLINE_USERS' | translate}}
              </p>
            </a>
          </li>
        </ul>
        <li id="TeamHeading" aria-expanded="true" aria-controls="collapseOne"
        *hasClaim="['MST_ADD_QUE','MST_UPDATE_QUE','MST_DELETE_QUE','MST_QUE_MEMBER']" data-toggle="collapse" data-target="#Team"
        class="collapsed">
        <a class="nav-link" [ngClass]="{'active': currentUrl == 'team'}">
          <i class="nav-icon fas fa-users-cog"></i>
          <!-- <span>{{'Contact' | translate}}</span> -->
          <span>Team</span>
          <span class="fas fa-angle-down rotate-arrow ml-auto"></span>
        </a>
      </li>
      <ul class="sub-menu collapse" id="Team" [ngClass]="{'show': currentUrl == 'team'}"
        aria-labelledby="TeamHeading" data-parent="#accordionExample">
        <li *hasClaim="['MST_VIEW_QUE','MST_ADD_QUE','MST_DELETE_QUE']" class="nav-item">
          <a [routerLink]="['/team']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
            class="nav-link">
            <i class="far fa-list-alt nav-icon"></i>
            <p>{{'LIST' | translate}}</p>
          </a>
        </li>
        <li *hasClaim="'MST_ADD_QUE'" class="nav-item">
          <a [routerLink]="['/team/add']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
            class="nav-link">
            <i class="fas fa-plus-square nav-icon"></i>
            <p>{{'ADD_NEW' | translate}}</p>
          </a>
        </li>
      </ul>
      <li id="RolesHeading" aria-expanded="true" aria-controls="collapseOne"
      *hasClaim="['ROLES_VIEW_ROLES','ROLES_ADD_ROLE']" data-toggle="collapse" data-target="#Roles"
      class="collapsed">
      <a class="nav-link" [ngClass]="{'active': currentUrl == 'roles'}">
        <i class="nav-icon fas fa-users-cog"></i>
        <span>{{'ROLES' | translate}}</span>
        <span class="fas fa-angle-down rotate-arrow ml-auto"></span>
      </a>
    </li>
    <ul class="sub-menu collapse" id="Roles" aria-labelledby="RolesHeading"
      [ngClass]="{'show': currentUrl == 'roles'}" data-parent="#accordionExample">
      <li *hasClaim="'ROLES_VIEW_ROLES'" class="nav-item">
        <a [routerLink]="['/roles']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
          class="nav-link">
          <i class="far fa-list-alt nav-icon"></i>
          <p>{{'LIST' | translate}}</p>
        </a>
      </li>
      <li *hasClaim="'ROLES_ADD_ROLE'" class="nav-item">
        <a [routerLink]="['/roles/manage']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
          class="nav-link">
          <i class="fas fa-plus-square nav-icon"></i>
          <p>{{'ADD_NEW' | translate}}</p>
        </a>
      </li>
    </ul>
        <li id="EmailHeading" aria-expanded="true" aria-controls="collapseOne"
          *hasClaim="['EMAIL_MANAGE_EMAIL_SMTP_SETTINS','EMAIL_MANAGE_EMAIL_TEMPLATES','EMAIL_SEND_EMAIL']"
          data-toggle="collapse" data-target="#Email" class="collapsed">
          <a class="nav-link" [ngClass]="{'active': currentUrl == 'email'}">
            <i class="nav-icon fas fa-envelope-open-text"></i>
            <span>{{'EMAIL' | translate}}</span>
            <span class="fas fa-angle-down rotate-arrow ml-auto"></span>
          </a>
        </li>
        <ul class="sub-menu collapse" id="Email" aria-labelledby="EmailHeading"
          [ngClass]="{'show': currentUrl == 'email'}" data-parent="#accordionExample">
          <li *hasClaim="'EMAIL_MANAGE_EMAIL_SMTP_SETTINS'" class="nav-item">
            <a [routerLink]="['/inbox']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="fas fa-inbox nav-icon"></i>
              <p>Inbox</p>
            </a>
          </li>
          <!-- <li *hasClaim="'EMAIL_MANAGE_EMAIL_SMTP_SETTINS'" class="nav-item">
            <a [routerLink]="['/sentMail']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="fas fa-inbox nav-icon"></i>
              <p>Sent Email</p>
            </a>
          </li> -->
          <li *hasClaim="'EMAIL_MANAGE_EMAIL_SMTP_SETTINS'" class="nav-item">
            <a [routerLink]="['/outbox']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="fas fa-inbox nav-icon"></i>
              <p>Outbox</p>
            </a>
          </li>
          <li *hasClaim="'EMAIL_MANAGE_EMAIL_SMTP_SETTINS'" class="nav-item">
            <a [routerLink]="['/email-smtp']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="fas fa-cog nav-icon"></i>
              <p>{{'SMTP_SETTINGS' | translate}}</p>
            </a>
          </li>
          <li *hasClaim="'EMAIL_MANAGE_EMAIL_TEMPLATES'" class="nav-item">
            <a [routerLink]="['/emailtemplate']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="fas fa-mail-bulk nav-icon"></i>
              <p>{{'EMAIL_TEMPLATE' | translate}}</p>
            </a>
          </li>
          <li *hasClaim="'EMAIL_SEND_EMAIL'" class="nav-item">
            <a [routerLink]="['/send-email']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="fas fa-paper-plane nav-icon"></i>
              <p>{{'SEND_EMAIL' | translate}}</p>
            </a>
          </li>
        </ul>
        <li id="SMSHeading" aria-expanded="true" aria-controls="collapseOne"
         *hasClaim="['MST_VIEW_SMSTEMPLATE','MST_ADD_SMSTEMPLATE','MST_EDIT_SMSTEMPLATE','MST_SMSSEND','MST_SMSSETTING','MST_DELETE_SMSTEMPLATE']"
          data-toggle="collapse" data-target="#SMS" class="collapsed">
          <a class="nav-link" [ngClass]="{'active': currentUrl == 'sms'}">
            <i class="nav-icon fas fa-envelope-open-text"></i>
            <span>{{'SMS' | translate}}</span>
            <span class="fas fa-angle-down rotate-arrow ml-auto"></span>
          </a>
        </li>
        <ul class="sub-menu collapse" id="SMS" aria-labelledby="SMSHeading"
          [ngClass]="{'show': currentUrl == 'sms'}" data-parent="#accordionExample">
          <li *hasClaim="'EMAIL_SEND_EMAIL'" class="nav-item">
            <a [routerLink]="['/sms-Config']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="fas fa-paper-plane nav-icon"></i>
              <p>SMS Configuration</p>
            </a>
          </li>
          <li *hasClaim="'MST_VIEW_SMSTEMPLATE'" class="nav-item">
            <a [routerLink]="['/message-template']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="fas fa-mail-bulk nav-icon"></i>
              <p>SMS Template</p>
            </a>
          </li>
          <li *hasClaim="'EMAIL_SEND_EMAIL'" class="nav-item">
            <a [routerLink]="['/send-message']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="fas fa-paper-plane nav-icon"></i>
              <p>Send SMS</p>
            </a>
          </li>
          
        </ul>
        <li id="ReportsHeading" aria-expanded="true" aria-controls="collapseOne" data-toggle="collapse"
          data-target="#Reports" class="collapsed" *hasClaim="['REP_INTERCATION_REPPORT','REP_INTERCATION_DUMP_REPORT','REP_INTERCATION_187_REPORT']">
          <a class="nav-link" [ngClass]="{'active': currentUrl == 'report'}">
            <i class="nav-icon fas fa-chart-bar"></i>
            <span>{{'REPORTS' | translate}}</span>
            <span class="fas fa-angle-down rotate-arrow ml-auto"></span>
          </a>
        </li>
        <ul class="sub-menu collapse " id="Reports" aria-labelledby="ReportsHeading"
          [ngClass]="{'show': currentUrl == 'report'}" data-parent="#accordionExample">
          <li *hasClaim="'REP_INTERCATION_REPPORT'" class="nav-item">
            <a [routerLink]="['/interaction-report']" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:true}" class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>
                Interaction Reports
              </p>
            </a>
          </li>
          <li *hasClaim="'REP_INTERCATION_DUMP_REPORT'" class="nav-item">
            <a [routerLink]="['/interaction-report/dump-reports']" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:true}" class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>
               Ticket Created Reports
              </p>
            </a>
          </li>
          <li *hasClaim="'REP_INTERCATION_DUMP_REPORT'" class="nav-item">
            <a [routerLink]="['/interaction-report/fcr-dump-reports']" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:true}" class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>
               FCR Reports
              </p>
            </a>
          </li>
          <!-- <li *hasClaim="'REP_INTERCATION_185_REPORT'" class="nav-item">
            <a [routerLink]="['/interaction-report/185-reports']" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:true}" class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>
                185 Reports
              </p>
            </a>
          </li> -->
          <li *hasClaim="'REP_INTERCATION_187_REPORT'" class="nav-item">
            <a [routerLink]="['/interaction-report/187-reports']" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:true}" class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>
                187 Reports
              </p>
            </a>
          </li>
          <li *hasClaim="'REP_INTERCATION_OPEN_REPORT'" class="nav-item">
            <a [routerLink]="['/interaction-report/all-teams-reports']" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:true}" class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>
                All Team Report
              </p>
            </a>
          </li>
          <li *hasClaim="'REP_INTERCATION_OPEN_REPORT'" class="nav-item">
            <a [routerLink]="['/interaction-report/open-reports']" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:true}" class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>
                Open Reports
              </p>
            </a>
          </li>
          <li *hasClaim="'REP_INTERCATION_CLOSED_REPORT'" class="nav-item">
            <a [routerLink]="['/interaction-report/closed-reports']" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:true}" class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>
                Closed Reports
              </p>
            </a>
          </li>
          <li *hasClaim="'REP_INTERCATION_PENDING_REPORT'" class="nav-item">
            <a [routerLink]="['/interaction-report/pending-reports']" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:true}" class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>
              Pending Reports
              </p>
            </a>
          </li>
          <li *hasClaim="'REP_INTERCATION_REOPEN_REPORT'" class="nav-item">
            <a [routerLink]="['/interaction-report/reopen-reports']" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:true}" class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>
                Reopen Reports
              </p>
            </a>
          </li>
          <li *hasClaim="'REP_INTERCATION_RESOLVED_REPORT'" class="nav-item">
            <a [routerLink]="['/interaction-report/resolved-reports']" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:true}" class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>
                Resolved Reports
              </p>
            </a>
          </li>
          <li *hasClaim="'REP_INTERCATION_L2L3_REPORT'" class="nav-item">
            <a [routerLink]="['/interaction-report/L2-L3-reports']" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:true}" class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>
                L2 L3 Reports
              </p>
            </a>
          </li>
          <li *hasClaim="'REP_INTERCATION_SERVEY_REPORT'" class="nav-item">
            <a [routerLink]="['/interaction-report/survey-reports']" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:true}" class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>Survey Reports</p>
            </a>
          </li>
          <li *hasClaim="'REP_INTERCATION_TAT_REPORT'" class="nav-item">
            <a [routerLink]="['/interaction-report/grp-tat-reports']" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:true}" class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>GRP TAT Reports</p>
            </a>
          </li>
          <li *hasClaim="'REP_INTERCATION_SLA14B_REPORT'" class="nav-item">
            <a [routerLink]="['/interaction-report/sla-14b-reports']" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:true}" class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>SLA 14B Reports</p>
            </a>
          </li>
          <li *hasClaim="'REP_INTERCATION_EMAILRESOLUTION_REPORT'" class="nav-item">
            <a [routerLink]="['/interaction-report/email-resolution-reports']" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:true}" class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>Email Resolution</p>
            </a>
          </li>
          <li *hasClaim="'REP_INTERCATION_PRODUCTVITY_REPORT'" class="nav-item">
            <a [routerLink]="['/interaction-report/agent-productivity-reports']" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:true}" class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>Agent Productivity</p>
            </a>
          </li>
          <li *hasClaim="'REP_INTERCATION_LOGIN_REPORT'" class="nav-item">
            <a [routerLink]="['/interaction-report/login-reports']" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:true}" class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>
                Login Reports
              </p>
            </a>
          </li>
          <li *hasClaim="'REP_INTERCATION_OUTBOUND_REPORT'" class="nav-item">
            <a [routerLink]="['/interaction-report/outbound-reports']" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:true}" class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>
                Outbound Reports
              </p>
            </a>
          </li>
          <li class="nav-item">
            <!-- *hasClaim="'REP_INTERCATION_SMS_REPORT'" -->
            <a [routerLink]="['/interaction-report/sms-reports']" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:true}" class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>
                SMS Reports
              </p>
            </a>
          </li>
        </ul>
        <li id="ProductHeading" aria-expanded="true" aria-controls="collapseOne" data-toggle="collapse"
          data-target="#ProductCategory" class="collapsed"
          >
          <!-- *hasClaim="['MST_VIEW_LOOKUP','PRO_MANAGE_BRAND','PRO_MANAGE_WAREHOUSE','PRO_MANAGE_BARCODE']" -->
          <a class="nav-link" [ngClass]="{'active': currentUrl == 'product'}">
            <i class="nav-icon fas fa-cubes"></i>
            <span>{{'Administrator' | translate}}</span>
            <span class="fas fa-angle-down rotate-arrow ml-auto"></span>
          </a>
        </li>
        <ul class="sub-menu collapse" [ngClass]="{'show': currentUrl == 'product'}" id="ProductCategory"
          aria-labelledby="ProductHeading" data-parent="#accordionExample">
          <li class="nav-item" *hasClaim="['MST_VIEW_STATUS']">
            <a [routerLink]="['/interaction/interaction-status']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p> Interaction Status</p>
            </a>
          </li>
          <li class="nav-item" *hasClaim="'MST_VIEW_CATEGORY'">
            <a [routerLink]="['/interaction/interaction-category']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>Interaction Category</p>
            </a>
          </li>
          <li class="nav-item" *hasClaim="['MST_DELETE_PROBLEM','MST_VIEW_PROBLEM','MST_EDIT_PROBLEM','MST_ADD_PROBLEM']">
            <a [routerLink]="['/problem']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>Problem Master</p>
            </a>
          </li>
          <li class="nav-item" *hasClaim="['MST_CTI_ADD_DISPOSTION','MST_CTI_DISPOSTION']">
            <a [routerLink]="['/disposition-master']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>Disposition Master</p>
            </a>
          </li>
          <li class="nav-item" *hasClaim="'MST_VIEW_CATEGORY'">
            <a [routerLink]="['/calendar']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>Calender Master</p>
            </a>
          </li>
          <li class="nav-item" *hasClaim="'MST_VIEW_ESCALMATRIX'">
            <a [routerLink]="['/escalation']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>Escalation Matrix</p>
            </a>
          </li>
          <li class="nav-item" *hasClaim="['MST_ADD_LOOKUP','MST_UPDATE_LOOKUP','MST_VIEW_LOOKUP']">
            <a [routerLink]="['/lookup']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="nav-icon fas fa-users-cog"></i>
              <p>Look up</p>
            </a>
          </li>
        </ul>
        <li id="SettingsHeading" aria-expanded="true" aria-controls="collapseOne" data-toggle="collapse"
          *hasClaim="['SETT_UPDATE_COM_PROFILE','SETT_MANAGE_COUNTRY','SETT_MANAGE_CITY']" data-target="#Settings"
          class="collapsed">
          <a class="nav-link" [ngClass]="{'active': currentUrl == 'settings'}">
            <i class="nav-icon fa fa-tools"></i>
            <span>{{'SETTINGS' | translate}}</span>
            <span class="fas fa-angle-down rotate-arrow ml-auto"></span>
          </a>
        </li>
        <ul class="sub-menu collapse" id="Settings" aria-labelledby="SettingsHeading"
          [ngClass]="{'show': currentUrl == 'settings'}" data-parent="#accordionExample">
          <li *hasClaim="'SETT_UPDATE_COM_PROFILE'" class="nav-item">
            <a [routerLink]="['/company-profile']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="fas fa-building nav-icon"></i>
              <p>{{'COMPANY_PROFILE' | translate}}</p>
            </a>
          </li>
          <li *hasClaim="'SETT_MANAGE_COUNTRY'" class="nav-item">
            <a [routerLink]="['/country']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>{{'COUNTRY' | translate}}</p>
            </a>
          </li>
          <li *hasClaim="'SETT_MANAGE_CITY'" class="nav-item">
            <a [routerLink]="['/cities']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>{{'CITY' | translate}}</p>
            </a>
          </li>
        </ul>
        <li id="LogsHeading" aria-expanded="true" aria-controls="collapseOne"
          *hasClaim="['LOGS_VIEW_LOGIN_AUDITS','LOGS_VIEW_ERROR_LOGS']" data-toggle="collapse" data-target="#Logs"
          class="collapsed">
          <a class="nav-link" [ngClass]="{'active': currentUrl == 'logs'}">
            <i class="fas fa-exclamation-triangle nav-icon"></i>
            <span>{{'LOGS' | translate}}</span>
            <span class="fas fa-angle-down rotate-arrow ml-auto"></span>
          </a>
        </li>
        <ul class="sub-menu collapse" id="Logs" aria-labelledby="LogsHeading" [ngClass]="{'show': currentUrl == 'logs'}"
          data-parent="#accordionExample">
          <li *hasClaim="'LOGS_VIEW_LOGIN_AUDITS'" class="nav-item">
            <a [routerLink]="['/login-audit']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>{{'LOGIN_AUDIT' | translate}}</p>
            </a>
          </li>
          <li *hasClaim="'LOGS_VIEW_ERROR_LOGS'" class="nav-item">
            <a [routerLink]="['/logs']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="far fa-list-alt nav-icon"></i>
              <p>{{'ERROR_LOGS' | translate}}</p>
            </a>
          </li>
          <li *hasClaim="'LOGS_VIEW_ERROR_LOGS'" class="nav-item">
            <a [routerLink]="['/email-service']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              class="nav-link">
              <i class="nav-icon fas fa-envelope-open-text"></i>
              <p>Email Service</p>
            </a>
          </li>
        </ul>
      </ul>
    </div>
  </div>
</div>
